import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'client',
        loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'forgot',
        loadChildren: () => import('./pages/auth/forgot/forgot.module').then(m => m.ForgotPageModule)
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule)
    },
    {
      path: 'coldcalls',
      loadChildren: () => import('./pages/coldcalls/coldcalls.module').then(m => m.ColdCallsModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
