import { User } from './../interfaces/user';
import { AuthResponse } from './../interfaces/auth-response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({  providedIn: 'root'})
export class AuthService {

  AUTH_SERVER_ADDRESS: string  =  environment.endpoints.authServer;
  public user: Observable<User>;

  private refreshTokenTimeout;
  private userSubject: BehaviorSubject<User>;

  constructor(
    private  http: HttpClient,
    private  storage: Storage,
    private router: Router
    ) {
      this.userSubject = new BehaviorSubject<User>(null);
      this.user = this.userSubject.asObservable();
   }

  public get userValue(): User {
    return this.userSubject.value;
  }

  async initialize() {

  }

  login(form: User) {
    return this.http.post<any>(`${this.AUTH_SERVER_ADDRESS}/accounts/authenticate`, form, { withCredentials: false })
        .pipe(map(user => {
            this.userSubject.next(user);
            this.storage.set('refreshToken', null);
            this.storage.set('refreshToken', user.refreshToken);
            this.stopRefreshTokenTimer();
            this.startRefreshTokenTimer();
            return user;
        }));
  }

  logout() {
    this.http.post<any>(`${this.AUTH_SERVER_ADDRESS}/accounts/revoketoken`, {}, { withCredentials: false }).subscribe();
    this.stopRefreshTokenTimer();
    this.storage.set('refreshToken', null);
    this.userSubject.next(null);
    return this.router.navigateByUrl("login");
  }

  refreshToken(rtoken?: string) {
    const token = rtoken ?? this.userValue?.refreshToken;
    return this.http
      .post<any>(`${this.AUTH_SERVER_ADDRESS}/accounts/refreshtoken`, { refreshToken: token }, { withCredentials: false })
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          this.storage.set('refreshToken', user.refreshToken);
          this.startRefreshTokenTimer();
          return user;
        }),
        catchError((e) => {
          if(e && e.status == 401) {
            return this.logout();
          }
        })
      );
  }

  register(user: User){
    return this.http.post<any>(`${this.AUTH_SERVER_ADDRESS}/accounts/register`, user, { withCredentials: false })
      .pipe(map(newUser => {
        this.userSubject.next(newUser);
        this.storage.set('refreshToken', newUser.refreshToken);
        this.startRefreshTokenTimer();
        return newUser;
      }));
  }

  reset(user): Observable<AuthResponse> {
    return this.http.post(`${this.AUTH_SERVER_ADDRESS}/accounts/resetpassword`, user, { withCredentials: false }).pipe(
      tap(async (res: AuthResponse) => {
      }),
      catchError(error => throwError(error))
    );
  }
    // helper methods


  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }

}
