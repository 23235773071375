import { AuthService } from './auth.service';
import { Storage } from '@ionic/storage';
export function appInitializer(authenticationService: AuthService,
    storage: Storage) {
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        storage.get("refreshToken").then(token=>{
            console.log(token)
        authenticationService.refreshToken(token)
            .subscribe()
            .add(resolve);
        })
    });
}